<template>
  <VContainer class="feedback-container">
    <VRow class="feedback-row">
      <VCol
        cols="12"
        class="feedback-main"
      >
        <VImg
          class="feedback-logo"
          :src="require('@/assets/images/logoPlexigridYellow.png')"
        />

        <span class="feedback-404">
          {{ code }}
        </span>

        <span class="feedback-message">
          {{ message }}
        </span>

        <RouterLink
          class="feedback-action mt-24"
          :to="{ name: 'DashboardView'}"
        >
          <VBtn color="#f4c020">
            Go Back Home
          </VBtn>
        </RouterLink>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'LYFeedback',

  props: {
    code: {
      type: [String, Number],
      required: true
    },

    message: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
.feedback-container {
  height: 100vh;
}

.feedback-row {
  height: 100%;
}

.feedback-main {
  place-self: center;
  display: grid;
}

.feedback-404 {
  font-size: 20rem;
  color: #f4c020;
}

.feedback-message {
  color: #fff;
  font-size: 1.2rem;
}

.feedback-404,
.feedback-message {
  font-weight: bold;
}

.feedback-logo,
.feedback-action {
  justify-self: center;
  width: 200px;
}
</style>
